<template>
  <div>

  </div>
</template>

<script>
import derive from "../../api/derive.js"

export default {
  data() {
    return {
      params: {
        "Type": "01",
        "Method": "PNE",
        "RequiredCheck": true,
        "OrganizationId": "",
        "ProjectId": "",
        "ProjectQuestionnaireId": "",
        "UserId": "",
        "Channel": "03",
        "CreatedBy": "",
        "UpdatedBy": ""
      },
    }
  },
  created() {
    this.params.OrganizationId = this.$route.query.OrganizationId
    this.params.ProjectId = this.$route.query.ProjectId
    this.params.ProjectQuestionnaireId = this.$route.query.ProjectQuestionnaireId
    this.params.UserId = this.$route.query.UserId
    this.params.Channel = this.$route.query.Channel
    this.params.FollowUpTaskId = this.$route.query.FollowUpTaskId
    this.params.RequiredCheck = this.$route.query.RequiredCheck
    this.getPrepare()
  },
  methods: {
    async getPrepare() {
      let res = await derive.Prepare(this.params)
      let { callBackParameter } = res.data.Data //syData
      let { callBackParameterObject } = res.data.Data
      let query = {
        fillinId: callBackParameterObject.UserId,
        fillinName: callBackParameterObject.UserName,
        phone: callBackParameterObject.UserTel,
        userId: callBackParameterObject.TargetId,
        userName: callBackParameterObject.TargetName,
        visitsNumber: callBackParameterObject.Frq,
        sysData: callBackParameter,
        qid: callBackParameterObject.ProjectQuestionnaireId,
        sysdataUser: callBackParameterObject.Channel === '04' ? true : false
      }
      query = this.objectToQueryString(query)
      let Url =
        `${window.global_config.BASE_URL1}/#/PcIframe?${query}&type=4&r=${new Date().getTime()}`;

      location.replace(Url)
    },
    objectToQueryString(params) {
      return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
    }
  }
}
</script>

<style lang="scss" scoped></style>